<template>
  <div class="invoice-body" id="invoice-body">
    <div v-show="show" id="invoice">
      <div :style="{'padding': '30px 15px','background-color': '#ffffff',
           'direction': 'rtl', 'text-align': 'right' }">
        <div v-if="rel_logo" style="width: 100%; text-align: center;padding-bottom: 10px;padding-top: 15px;"><img :src="rel_logo" alt="" style="width: 80px;"></div>
<!--        <h4 v-if="customer_type == 1 " class="text-center font-weight-800">فاتورة ارجاع ضريبية مبسطة SIMPLE TAX REFUND INVOICE</h4>-->
<!--        <h4 v-else-if="customer_type == 2" class="text-center font-weight-800">فاتورة ارجاع ضريبية TAX REFUND INVOICE</h4>-->
<!--        <h4 v-else class="text-center font-weight-800">فاتورة INVOICE</h4>-->
        <h4 class="text-center font-weight-800">{{data ? data.invoice_title_ar: ''}} {{data ? data.invoice_title_en : '' }}</h4>

        <h4 v-if="inContentTemp('invoice_code')" class="text-center font-weight-800" style="margin-top: 15px">
          رقم الاشعار: {{ data.credit_code }}
        </h4>
        <h4 v-if="data.sales_invoice_code" class="text-center font-weight-800" style="margin-top: 15px">
          رقم الفاتورة: {{ data.sales_invoice_code }}
        </h4>

        <h3 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.company && data.company.business_name && inContentTemp('business_name_ar')">{{ data.company.business_name }}</h3>
        <template v-if="data.branch">
          <h5 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.branch.name">{{ data.branch.name }}</h5>
          <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">
            <span v-if="data.branch.country">{{ data.branch.country ? data.branch.country : ''}}</span>
            <span v-if="data.branch.city">{{ data.branch.city ? ' - ' + data.branch.city : '' }}</span>
            <span v-if="data.branch.address_1">{{data.branch.address_1 ? ' - ' + data.branch.address_1 : ''}}</span>
          </p>
        </template>
        <template v-else>
          <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">
            <span v-if="inContentTemp('company_country_name') && data.company.country_name">{{ data.company.country_name ? data.company.country_name : ''}}</span>
            <span v-if="inContentTemp('company_city_name') && data.company.city_name">{{ data.company.city_name ? ' - ' + data.company.city_name : '' }}</span>
            <span v-if="inContentTemp('company_address_1') && data.company.address_1">{{data.company.address_1 ? ' - ' + data.company.address_1 : ''}}</span>
          </p>
          <p v-if="inContentTemp('company_mobile') && data.company.mobile" style="direction: ltr;display: block;text-align: center;">{{data.company.mobile ? data.company.mobile : ''}}</p>
        </template>

<!--        <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">-->
<!--          <span v-if="inContentTemp('company_city_name')">{{ data.company ? (data.company.city_name ? ' - ' + data.company.city_name : '') : '' }}</span>-->
<!--          &lt;!&ndash;          <span v-if="inContentTemp('company_state')">{{ data.company ? (data.company.state ? ' - ' + data.company.state : '') : '' }}</span>&ndash;&gt;-->
<!--          <span v-if="inContentTemp('company_address_1')">{{ data.company ? (data.company.address_1 ? ' - ' + data.company.address_1 : '') : '' }}</span>-->
<!--          <span v-if="inContentTemp('company_postal_code')">{{ data.company ? (data.company.postal_code ? ' - ' + data.company.postal_code : '') : '' }}</span>-->
<!--        </p>-->

        <div v-if="data.pos_store && is_show_contact && (mobileFullData(data.pos_store.mobile) || mobileFullData(data.pos_store.mobile2))" style="width: 100%;display: block;background-color: #cccccc;">
          <h5 style="margin-bottom: 0;text-align: center;padding-top: 5px;padding-bottom: 5px;">
            <span>التواصل: </span>
            <span v-if="mobileFullData(data.pos_store.mobile)" style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile) }}</span>
            <template v-if="mobileFullData(data.pos_store.mobile2)">
              <span style="direction: ltr;display: inline-block;margin-right: 5px;margin-left: 5px;" v-if="mobileFullData(data.pos_store.mobile2) "> - </span>
              <span style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile2) }}</span>
            </template>
          </h5>
        </div>
        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 5px 10px;">
          <p style="margin-bottom: 0;margin-top: 0;" v-if="inContentTemp('invoice_date')"><span><b> تاريخ الاشعار: </b> <span> {{ data.create_date_time }} </span></span></p>
          <p style="margin-bottom: 0;margin-top: 10px;">
            <span v-if="inContentTemp('employee_user_name') && data.employee_user_name">
              <b> أمين الصندوق: </b>
              <span> {{ data.pos_session.user_name }} </span>
            </span>
          </p>
        </div>

        <div v-if="is_show_customer_details" style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;">
          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer && data.customer.name">
            <span v-if="inContentTemp('fullname')" style="width: 100%;"><span><b> العميل: </b> </span><span> {{ data.customer.name }} </span></span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile) && data.customer.mobile" style="margin-right: 10px;margin-left: 10px;"> | </span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile)" style="direction: ltr;display: inline-block;width: 100%;"> {{ mobileFullData(data.customer.mobile) }} </span>
          </p>
          <p style="margin: 0;" v-if="data.customer">
            <span v-if="inContentTemp('country_name')">{{ data.customer.country_name ? data.customer.country_name : '' }} </span>
            <span v-if="inContentTemp('city_name')"> {{ data.customer.city_name ? ' - ' + data.customer.city_name : ''  }} </span>
            <span v-if="inContentTemp('customer_address_1')">{{ data.customer.address_1 ? ' - ' + data.customer.address_1  : '' }}</span>
          </p>
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')">
            <span><b> الرقم الضريبي: </b></span>
            <span>{{ data.customer.tax_register }}</span>
          </p>
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.commercial_register && inContentTemp('commercial_register')">
            <span><b> السجل التجاري: </b></span>
            <span>{{ data.customer.commercial_register }}</span>
          </p>

          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer">
            <span v-if="inContentTemp('customer_code')" style="width: 100%;"><span><b> رقم العميل: </b> </span><span v-if="data.customer.customer_code">{{ data.customer.customer_code }}#</span></span>
            <span v-if="(inContentTemp('customer_debit') && data.customer_balance && data.customer_balance.debit) || (inContentTemp('customer_credit') && data.customer_balance && data.customer_balance.credit )" style="margin-right: 10px;margin-left: 10px;">|</span>
            <span style="width: 100%;" v-if="data.customer_balance && data.customer_balance.credit && inContentTemp('customer_credit')">
                <span><b> الرصيد: </b> </span><span>{{ data.customer_balance.credit }}</span>
              </span>
            <span v-else-if="data.customer_balance && data.customer_balance.debit && inContentTemp('customer_debit')">
                <span style="width: 100%;"><b> الرصيد المستحق: </b> </span><span>{{ data.customer_balance.debit }}</span>
              </span>
          </p>
          <p style="margin-bottom: 0;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')"><span><b> الرقم الضريبي: </b> </span><span> {{ data.customer.tax_register }} </span></p>
        </div>

        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div v-if="products_template_design == 1" style="margin-top: 15px;">
          <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;" v-for="(row, index) in items_list" :key="index">
            <div style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;" v-if="inProductTableSetting('item')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('product_name', 'ar')}}</b></span>
                <span style="display: block;">{{ row.item ? row.item.name : '' }}</span>
              </p>
              <p style="margin: 0;" v-if="inProductTableSetting('unit')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('unit', 'ar')}}</b></span>
                <span style="display: block;">{{ row.unit_name ? row.unit_name : '' }}</span>
              </p>
              <p style="margin: 0;" v-if="inProductTableSetting('qty')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('quantity', 'ar')}}</b></span>
                <span style="display: block;">{{ row.qty }}</span>
              </p>
              <p style="margin: 0;" v-if="inProductTableSetting('unit_price')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('unit_price', 'ar')}} </b></span>
                <span style="display: block;">{{ row.unit_price }}</span>
              </p>
            </div>
            <div style="margin-bottom: 0;margin-top:10px;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('total_before_tax')">
                <span><b>{{getLabelShortcut('total_before_tax', 'ar')}}</b></span>
                <span> {{  row.subtotal_before_discount}}</span>
              </p>
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('amount_tax')">
                <span><b>{{getLabelShortcut('tax_val', 'ar')}}</b></span>
                <span> {{ row.amount_tax ? row.amount_tax : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('discount_calc')">
                <span><b>{{getLabelShortcut('discount_calc', 'ar')}}</b></span>
                <span> {{ row.discount_calc ? row.discount_calc : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('subtotal_after_tax')">
                <span><b>{{getLabelShortcut('total_after_tax', 'ar')}}</b></span>
                <span> {{ row.subtotal_after_tax ? row.subtotal_after_tax : '0' }}</span>
              </p>
            </div>

            <p><span style="border-bottom: 2px dotted #000;width: 70%;height: 0;display: block;margin: 0 auto;"></span></p>
          </div>
        </div>
        <div v-else-if="products_template_design == 2" style="margin-top: 15px;">
          <table class="table" style="margin-top: 15px">
            <thead>
            <tr>
              <th v-if="inProductTableSetting('item')">{{getLabelShortcut('product_name', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('unit')">{{getLabelShortcut('unit', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('qty')">{{getLabelShortcut('quantity', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('unit_price')">{{getLabelShortcut('unit_price', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('discount_calc')">{{getLabelShortcut('discount_calc', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('amount_tax')">{{getLabelShortcut('tax_val', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('total_before_tax')">{{getLabelShortcut('total_before_tax', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('subtotal_after_tax')">{{getLabelShortcut('total_after_tax', 'ar')}}</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(row, index) in items_list" :key="index">
              <td style="overflow: hidden;" v-if="inProductTableSetting('item')"><span style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;">{{ row.item ? row.item.name : '' }}</span>
              </td>
              <td class="text-center" v-if="inProductTableSetting('unit')">{{ row.unit_name }}</td>
              <td class="text-center" v-if="inProductTableSetting('qty')">{{ row.qty }}</td>
              <td class="text-center" v-if="inProductTableSetting('unit_price')">{{ row.unit_price }}</td>
              <td class="text-center" v-if="inProductTableSetting('discount_calc')">{{ row.discount_calc ? row.discount_calc : '0' }}</td>
              <td class="text-center" v-if="inProductTableSetting('amount_tax')">{{ row.amount_tax ? row.amount_tax : '0' }}</td>
              <td class="text-center" v-if="inProductTableSetting('total_before_tax')">{{ row.subtotal_after_discount ? row.subtotal_after_discount : '0' }}</td>
              <td class="text-center" v-if="inProductTableSetting('subtotal_after_tax')">{{ row.subtotal_after_tax ? row.subtotal_after_tax : '0' }}</td>
              <!--          <td>{{ row.subtotal_before_tax }}</td>-->
            </tr>
            </tbody>
          </table>
        </div>

        <div style="margin-top: 15px;">
          <div style="min-width: 264px;padding-left: 5px;">
            <div class="total-border d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_subtotal')">
              <div><h5 class="mb-0">الاجمالي قبل الضريبة \ Sub Total</h5></div>
              <div><span><b>{{ data.invoice_total_before_taxes ? data.invoice_total_before_taxes : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>
            <div class="total-border d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_discount')">
              <div><h5 class="mb-0">الخصم \ Discount</h5></div>
              <div><span class="sum-sum"><b>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>

            <div class="total-border d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_taxes')">
              <div><h5 class="mb-0">ضريبة القيمة المضافة \ VAT 15%</h5></div>
              <div><span class="sum-sum"><b>{{ data.credit_taxes ? data.credit_taxes : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>

            <div class="total-border d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_after_taxes')">
              <div><h5 class="mb-0">الاجمالي شامل الضريبة \ Total</h5></div>
              <div><span><b>{{ data.credit_total ? data.credit_total : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>

            <div v-if="is_payment_details  && showPaymentDetails(data.payments)">
              <div><h5 class="mb-0">طرق الدفع \ Payment methods</h5></div>
              <div style="width: 100%;">
                <span style="margin-bottom: 10px;display: block;" v-for="(_payment , _index) in data.payments" :key="'_payment'+_index">
                  <template v-if="_payment.payment_method && _payment.amount != 0 && _payment.amount != null">
                    <span> {{ _payment.payment_method	 }} </span> <span> {{ _payment.amount }} </span> <span> {{ data.currency_code }} </span>
                  </template>
                </span>
              </div>
            </div>

          </div>

          <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
          <div class="text-center " style="margin: 5px auto;width: 166px;height: 203px;margin-top: 15px;">
            <div v-show="data.data_qr_code" style="padding: 5px;width: 166px;height: 203px;">
              <qr-code :text="data.data_qr_code ? data.data_qr_code : '1'" :size="size" :error-level="'L'"></qr-code>
            </div>
          </div>

          <div v-if="pos_policy && is_show_terms_and_conditions">
            <p v-if="is_break_before_footer"><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
            <h4 class="text-center">الشروط والأحكام</h4>
            <div class="mb-0 ql-editor" style="float: initial;text-align: left;width: 100%;height: auto;padding: 0;" v-html="pos_policy"></div>

          </div>
          <!--          <div v-if="data.usage_policy && is_show_terms_and_conditions">-->
          <!--            <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>-->
          <!--            <p>{{ data.usage_policy }}</p>-->
          <!--          </div>-->


        </div>

      </div>

    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VueQRCodeComponent from "vue-qrcode-component";
import jsPdfExport from "@/core/config/jsPdfExport";
import invoicesLabelOfItems from "@/core/config/mix/invoicesLabelOfItems";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "point-sale-credits",
  components: {
    "qr-code": VueQRCodeComponent,
  },
  data() {
    return {
      fetched: false,
      lang: this.$i18n.locale,
      mainRoute: "sales/credits",
      startLink: null,
      idEditing: this.$route.params.sales_invoice_id
          ? this.$route.params.sales_invoice_id
          : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      data: {},
      items_list: [],
      size: 155,
      calc_total: 0,
      show: false,

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'PosSales',
      template: null,
      content_temp: [],
      is_break_before_footer: false,
      is_payment_details: false,
      is_empty_row: false,
      is_show_contact: false,
      is_show_customer_details: false,
      is_show_terms_and_conditions: false,
      products_template_design: 1,
      print_size: '80',
      customer_type: null,
      product_table_setting: [],
      label_shortcut_details: [],
      is_use_label_shortcut: false,
      pos_policy : null
    };
  },
  computed: {
    rel_logo: function () {
      if (this.data && this.data.company && this.data.company.logo_url) {
        return this.data.company.logo_url;
      } else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }
    },
    fileName: function (){
      let _invoice_label = this.$i18n.locale ==  'ar' ? 'اشعار دائن' : 'Sales credit';
      let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      let _invoice_code = (this.data.credit_code) ? this.data.credit_code : '';
      return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  methods: {
    ...invoicesLabelOfItems,
    ...cssStypeForPrintReport,
    getLabelShortcut(_slug, lang){
      let _is_use_label = this.is_use_label_shortcut;
      // let _is_use_label = this.is_multi_language_label && this.is_custom_product_size_column;
      return this.getLabelShortcutFromSettingsPrint( _slug, lang, this.label_shortcut_details, _is_use_label);
    },
    inProductTableSetting(content) {
      if (this.product_table_setting.includes(content))
        return true;
      else
        return false;
    },
    mobileFullData(mobile){
      if (mobile){
        if (mobile.length > 5){
          return mobile;
        }
      }
      return null;
    },
    formatNumber(number) {
      // Format the number with commas if it's greater than 5 digits
      return number > 9999 ? new Intl.NumberFormat().format(number) : number;
    },
    showPaymentDetails(payment_amount_details){
      if (payment_amount_details && payment_amount_details.length > 0){
        let status = false;
        payment_amount_details.forEach((row)=>{
          if (row.amount != 0 && row.amount != null){
            status = true;
          }
        });
        return status;
      }else {
        return false;
      }
    },
    async getData() {
      let _id = this.idEditing;
      // await ApiService.get(`${this.mainRoute}/print_last_invoice`).then((response) => {
      await ApiService.get(`${this.mainRoute}/${_id}/print`).then((response) => {
        this.data = response.data.data;
        let _inner_data = response.data.data;
        this.items_list = response.data.data.items_list;

        let _taxes = response.data.data.invoice_total_taxes
            ? parseFloat(response.data.data.invoice_total_taxes) / 100
            : 0;
        let _subtotal = response.data.data.invoice_subtotal
            ? parseFloat(response.data.data.invoice_subtotal)
            : 0;

        let _total_taxes = _subtotal * _taxes;
        Object.assign(_inner_data, {invoice_taxes_total: _total_taxes});

        this.calc_total = response.data.data.invoice_total;

        if (response.data.data && response.data.data.customer) {
          this.customer_type = response.data.data.customer.customer_type;
        }

        this.show = true;
      });
    },

    async printContent() {
      if (document.getElementById('invoice-body')) {
        const content = document.getElementById('invoice-body').innerHTML;

        const printWindow = window.open('', '_self');

        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>' + this.getQLEditorStyleForPrintReport());
        printWindow.document.write(`

              @media print {
                @page {
                  size: auto;
                  margin: 2mm;
                }
              }
              .store-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 99px;
                margin: 13px;
              }
              #invoice {
                font-family: "NotoSansArabic";
                width: ${this.print_size}mm;
                position: relative;
              }
              h5{
                margin:0;
              }
              html, body {
                    margin: 0.39mm 0.27mm;
                    background: #EEF0F8;
              }

              table, thead, tr, th, tbody, td {

                font-size: 14px;
                background-color: transparent !important;
              }

              table{
                border: 1px solid #000000;
                border-radius: 6px;
                border-color: #000000 !important;
                width: 100%;
              }

              .text-center {
                text-align: center !important;
              }

              th, p, h5 {

                font-size: 14px !important;

              }

              .special-border {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
              }
              .table td {
                border-left: 1px solid #000000 !important;
                border-top: 0;
              }
              .table thead th {
                border-left: 1px solid #000000;
                border-bottom: 1px solid #000000;
              }
              .table thead tr {
                border: 0 !important;
              }
              .table thead th:last-child {
                border-left: 0 !important;
              }
              .table td:last-child {
                border-left: 0 !important;
              }
              .table th, .table td {
                padding: 5px !important;
              }
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
.ql-editor img{
  max-width: 100%;
}
          `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          printWindow.print();
          printWindow.close();
        }, 500);
      }
    },
    printData() {

      let _id = "invoice";
      if (this.type == "pdf") {
        this.exportPDF(_id);
      } else {
        this.printContent();
      }
    },
    exportPDF(_id) {
      let that = this;
      let element = document.getElementById(_id);
      let width = element.clientWidth;
      let height = element.clientHeight;

      let pdf = jsPdfExport("p", "px", [width, height]);

      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 1,
        },
        callback: function (pdf) {
          pdf.save(that.fileName);
          // pdf.autoPrint();
          // pdf.output('pdfobjectnewwindow');
        },
      });


    },


    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_show_contact = response.data.data.is_show_contact ? response.data.data.is_show_contact : false;
        this.print_size = response.data.data.print_size ? response.data.data.print_size : '80';
        this.is_show_customer_details = response.data.data.is_show_customer_details ? response.data.data.is_show_customer_details : false;
        this.is_show_terms_and_conditions = response.data.data.is_show_terms_and_conditions ? response.data.data.is_show_terms_and_conditions : false;
        this.products_template_design = response.data.data.products_template_design ? response.data.data.products_template_design : 1;
        this.product_table_setting = response.data.data.product_table_setting ? response.data.data.product_table_setting : [];
        this.label_shortcut_details = response.data.data.label_shortcut_details ? response.data.data.label_shortcut_details : [];
        this.is_use_label_shortcut = response.data.data.is_use_label_shortcut ? response.data.data.is_use_label_shortcut : false;
        this.pos_policy = response.data.data.usage_policy ? response.data.data.usage_policy : null;

      });
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
    },
  },
  mounted() {
    let promise = this.getData();
    let promise1 = this.printSetting();
    Promise.all([promise, promise1]).then(() => {
      this.printData();
    });
  },
};
</script>

<style>
@media print {
  @page {
    size: auto;
    margin: 2mm;
  }
}

.store-logo {
  position: absolute;
  top: 0;
  right: 0;
  height: 99px;
  margin: 13px;
}

#invoice {
  font-family: "Amiri";
  width: 76mm;
  position: relative;
}

h5 {
  margin: 0;
}

html, body {
  margin: 0.39mm 0.27mm;
  background: #EEF0F8;
}

table, thead, tr, th, tbody, td {

  font-size: 14px;
  background-color: transparent !important;
}

table {
  border: 1px solid #000000;
  border-radius: 6px;
  border-color: #000000 !important;
  width: 100%;
}

.text-center {
  text-align: center !important;
}

th, p, h5 {

  font-size: 14px !important;

}

.special-border {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.table td {
  border-left: 1px solid #000000 !important;
  border-top: 0;
}

.table thead th {
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.table thead tr {
  border: 0 !important;
}

.table thead th:last-child {
  border-left: 0 !important;
}

.table td:last-child {
  border-left: 0 !important;
}

.table th, .table td {
  padding: 5px !important;
}
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
.ql-editor img{
  max-width: 100%;
}
</style>